export const productPropList = [
    { label: 'vendor', type: 'number', required: true },
    { label: 'category', type: 'number', required: true },
    { label: 'image', type: 'file', required: true },
    { label: 'name', type: 'text', required: true },
    { label: 'name_ru', type: 'text', required: false },
    { label: 'name_uz', type: 'text', required: false },
    { label: 'name_en', type: 'text', required: false },
    { label: 'description', type: 'text', required: false },
    { label: 'description_ru', type: 'text', required: false },
    { label: 'description_uz', type: 'text', required: false },
    { label: 'description_en', type: 'text', required: false },
    { label: 'price', type: 'number', required: false },
    { label: 'actual_price', type: 'number', required: false },
    { label: 'retail_price', type: 'number', required: true },
    { label: 'discount_price', type: 'number', required: false },
    { label: 'is_published', type: 'checkbox', required: false },
    { label: 'is_cookmen', type: 'checkbox', required: false },
    { label: 'is_new', type: 'checkbox', required: false },
    { label: 'is_active', type: 'checkbox', required: false },
    { label: 'is_featured', type: 'checkbox', required: false },
    { label: 'order_in_list', type: 'number', required: true },
    // { label: 'photo', type: 'number', required: true },
];
export const GoodsPropList = [
    { label: 'vendor', type: 'number', required: true },
    { label: 'category', type: 'number', required: true },
    { label: 'name', type: 'text', required: true },
    { label: 'name_ru', type: 'text', required: false },
    { label: 'name_uz', type: 'text', required: false },
    { label: 'name_en', type: 'text', required: false },
    { label: 'description', type: 'text', required: false },
    { label: 'description_ru', type: 'text', required: false },
    { label: 'description_uz', type: 'text', required: false },
    { label: 'description_en', type: 'text', required: false },
];
export const GoodsCatPropList = [
    { label: 'name', type: 'text', required: true },
    { label: 'name_ru', type: 'text', required: false },
    { label: 'name_uz', type: 'text', required: false },
    { label: 'name_en', type: 'text', required: false },
];
export const SessionPropList = [
    { label: 'name', type: 'text', required: true },
    { label: 'note', type: 'text', required: true }
];
export const IncomePropList = [
    { label: 'name', type: 'text', required: true },
    { label: 'date', type: 'date', required: true }
];
export const unitPropList = [
    { label: 'name', type: 'text', required: true },
    { label: 'name_ru', type: 'text', required: false },
    { label: 'name_uz', type: 'text', required: false },
    { label: 'name_en', type: 'text', required: false },
    { label: 'value', type: 'number', required: true },
    { label: 'is_active', type: 'checkbox', required: false },
    { label: 'parent', type: 'number', required: false },
];
export const categoryPropList = [
    { label: 'name', type: 'text', required: true },
    { label: 'name_ru', type: 'text', required: false },
    { label: 'name_uz', type: 'text', required: false },
    { label: 'name_en', type: 'text', required: false },
    { label: 'short_name', type: 'text', required: true },
    { label: 'short_name_ru', type: 'text', required: false },
    { label: 'short_name_uz', type: 'text', required: false },
    { label: 'short_name_en', type: 'text', required: false },
    { label: 'slug', type: 'text', required: true },
    { label: 'description', type: 'text', required: false },
    { label: 'description_ru', type: 'text', required: false },
    { label: 'description_uz', type: 'text', required: false },
    { label: 'description_en', type: 'text', required: false },
    { label: 'icon', type: 'text', required: false },
    { label: 'image', type: 'file', required: true },
    { label: 'order_in_list', type: 'number', required: true },
    { label: 'attribute_data', type: 'text', required: true },
    { label: 'attribute_data_ru', type: 'text', required: false },
    { label: 'attribute_data_uz', type: 'text', required: false },
    { label: 'attribute_data_en', type: 'text', required: false },
    { label: 'is_active', type: 'checkbox', required: false },
    { label: 'parent', type: 'number', required: false },
];

export const orderPropList = [
    {
        label: 'status',
        type: 'enum',
        enum: [
            'New',
            'Start',
            'Done',
            'Complete',
            'Delivered',
            'Delivering',
            'Remove',
            'Canceled',
            'DeliveryRemove',
            'DeliveryCanceled',
        ],
        required: true,
    },
    {
        label: 'order_type',
        type: 'enum',
        enum: [
            // 'inplace',
            // 'yourself',
            'delivery',
            'weself',
            'websiteweself',
            'websitedelivery',
            'telegramweself',
            'telegramdelivery',
        ],
        required: false,
    },
    { label: 'payment_method', type: 'enum', enum: ['CASH', 'CARD'], required: false },
    { label: 'branch', type: 'enum', enum: ['Lola','Sardoba'], required: false },
    { label: 'branch', type: 'enum', enum: [
            {value: "1", label:'Lola'},
            {value: "2", label:'Sardoba'}
        ], required: false },
    {
        label: 'shipping_address',
        type: 'object',
        object: [
            { label: 'is_primary', type: 'checkbox', required: false },
            { label: 'street_address_1', type: 'string', required: false },
            { label: 'street_address_2', type: 'string', required: false },
            { label: 'phone', type: 'tel', required: false },
            { label: 'note', type: 'text', required: false },
        ],
        required: false,
    },
    { label: 'subtotal', type: 'number', required: false },
    { label: 'total_discount', type: 'number', required: false },
    { label: 'shipping_cost', type: 'number', required: false },
    { label: 'sold_date', type: 'date', required: false },
    { label: 'note', type: 'text', required: false },
    { label: 'modifiedby', type: 'number', required: false },
];

export const userPropList = [
    { label: 'first_name', type: 'text', required: false },
    { label: 'last_name', type: 'text', required: false },
    { label: 'phone', type: 'tel', required: true },
    { label: 'email', type: 'email', required: true },
    { label: 'username', type: 'text', required: false },
    { label: 'role', type: 'text', required: false },
    { label: 'password', type: 'password', required: true },
];

export const customerPropList = [
    { label: 'phone', type: 'tel', required: true },
    { label: 'first_name', type: 'text', required: false },
    { label: 'last_name', type: 'text', required: false },
    { label: 'email', type: 'email', required: false },
    { label: 'source', type: 'text', required: false },
    { label: 'about', type: 'text', required: false },
    { label: 'note', type: 'text', required: false },
    { label: 'user', type: 'number', required: false },
];

export const callPropList = [
    { label: 'phone_number', type: 'tel', required: true },
    { label: 'type', type: 'enum', enum: ['INCOMING', 'OUTGOING'], required: true },
    { label: 'status', type: 'enum', enum: ['MISSED', 'OUTGOING', 'ANSWERED'], required: true },
    { label: 'user', type: 'number', required: false },
    { label: 'customer', type: 'number', required: false },
];

export const campaignPropList = [
    { label: 'name', type: 'text', required: true },
    {
        label: 'status',
        type: 'enum',
        enum: ['new', 'in_progress', 'ended', 'fail'],
    },
];

export const smsPropList = [
    { label: 'phone_number', type: 'tel', required: true },
    { label: 'text', type: 'text', required: true },
    {
        label: 'status',
        type: 'enum',
        enum: ['new', 'in_progress', 'ended', 'fail'],
        required: true,
    },
    { label: 'assign', type: 'number', required: true },
    { label: 'user', type: 'number', required: true },
];
