import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../components/TableView';
import FormController from '../components/survey.edit';
import CallsForm from './forms/survey.form';
import { callPropList as propList } from '../../../constants/propListConstants';
import getColumns from '../../../constants/tableColumnConstants';
import {
    surveyGet,
    callPost,
    callPut,
    surveyDelete
} from '../../../utils/apiRequest';
export default function Customers() {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pagesToFetch, setPagesToFetch] = useState([]);
    const [waiting, setWaiting] = useState(false);

    const { t } = useTranslation();
    const columns = getColumns('survey', t);

    useEffect(() => {
        setWaiting(true);
        const prms = [];
        for (let i of pagesToFetch) prms.push(surveyGet(i));

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });

                setData([...data, ...obj]);
                setCount(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setData([]);
            })
            .finally(() => setWaiting(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagesToFetch]);

    useEffect(() => {
        setData([]);
        setPagesToFetch([1]);
    }, [refresh]);
    console.log(count)
    return (
        <>
            <Table
                rows={data}
                count={count}
                columns={columns}
                waiting={waiting}
                handleFormOpen={setFormOpen}
                reqDelete={surveyDelete}
                refreshPage={() => setRefresh(!refresh)}
                setPagesToFetch={setPagesToFetch}
            />
            <FormController
                open={formOpen}
                data={data}
                propList={propList}
                header={t('propList.survey')}
                render={(input, handleTextChange, handleFileChange) => (
                    <CallsForm
                        open={formOpen}
                        input={input}
                        setFormOpen={setFormOpen}
                        refreshPage={() => setRefresh(!refresh)}
                        handleTextChange={handleTextChange}
                        handleFileChange={handleFileChange}
                    />
                )}
                handleClose={() => {
                    setFormOpen(false);
                }}
                refreshPage={() => setRefresh(!refresh)}
                reqPost={callPost}
                reqPatch={callPut}
            />
        </>
    );
}
