import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TableFooter from '@mui/material/TableFooter';
import EmptyList from "../../components/empty.list"
import TablePagination from '@mui/material/TablePagination';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../../constants/styleConstants';
import * as cookies from '../../../../utils/Cookies';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // height: '100px',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: '#ccc',
    cursor: 'pointer'
  },
}));

export default function CustomizedTables({listSize, page, loading, perPage, columns, data, type, setFirstClick, firstClick, handleChangePage, handleChangePageRow}) {
  const theme = useSelector((state) => state.theme);
  const lang = cookies.get('lang')
  let colorValues;
  if (theme.name === 'dark') {
    colorValues = VALUES;
  } else if (theme.name === 'green') {
    colorValues = GREENVALUES;
  } else {
    colorValues = DARKVALUES;
  }
  //
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorValues.colorBgPrimary,
      color: colorValues.colorPrimary,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));
  const { t } = useTranslation();
  const [state, setState] = React.useState([])

  React.useEffect(() => {
    if (data) setState(data)
  }, [data])
  // console.log(data?.length>0 && data.reduce(function (acc, obj) { return acc + obj.calc_total; }, 0));
  return (
    <>
    <TableContainer component={Paper} style={{ height: '80vh', overflowY: 'scroll', borderRadius:30 }}>
      <Table stickyHeader className="table_body_20" sx={type === 'one' ? { minWidth: 490 } : type === 'two' ? { minWidth: 380 } : { minWidth: 420 }} aria-label="customized table">
        <TableHead >
          <TableRow  style={{ 'backgroundColor': '#f5f5f5', "height": '35px' }}>
            {columns?.map(item => (<StyledTableCell key={item.id}>{item.label}</StyledTableCell>))}
          </TableRow>
        </TableHead>
        {
          loading ? 
          <TableBody>
            <TableCell colSpan={12} style={{ textAlign: 'center' }}>
              <div>loading...</div>
            </TableCell>
          </TableBody> :
          state?.length < 1 ?
          <TableBody>
            <TableCell colSpan={12} style={{ textAlign: 'center' }}>
              <EmptyList height="400px" top="100px" />
            </TableCell>
          </TableBody> :
          <TableBody style={{ positon: 'relative' }}>
            {state && state?.map((row, index) => (
              <StyledTableRow style={firstClick?.id === row?.id ? { background: '#ccc' } : null} key={index} onClick={() => type === 'one' ? setFirstClick(row) : null}>
                <StyledTableCell component="th" scope="row">{row?.id}</StyledTableCell>
                {
                  type === 'one' &&
                  <>
                    <StyledTableCell component="th" scope="row">{row[`name_${lang}`] || t('profile.nodata')}</StyledTableCell>
                    <StyledTableCell component="th" scope="row">{row['price'] || t('profile.nodata')}</StyledTableCell>
                    <StyledTableCell component="th" scope="row">{row['retail_price'] || t('profile.nodata')}</StyledTableCell>
                  </>
                }
                {
                  type === 'three' &&
                  <>
                    <StyledTableCell component="th" scope="row">{row[`name_${lang}`] || t('profile.nodata')}</StyledTableCell>
                    <StyledTableCell component="th" scope="row">{row['quantity'] || t('profile.nodata')}</StyledTableCell>
                    <StyledTableCell component="th" scope="row">{row['price'] || t('profile.nodata')}</StyledTableCell>
                    <StyledTableCell component="th" scope="row">{row['calc_total']?.toString() || parseInt(row['price'])*parseInt(row['quantity'])}</StyledTableCell>
                  </>
                }
              </StyledTableRow>
            ))}
          </TableBody>
        }
        {
          type === 'three' &&
          <TableFooter style={{
            width: 420,
            position: "sticky",
            bottom: 60, right: 0,
            marginTop:'auto',
            padding:'2px 5px'
          }} >
            <div style={{position:'absolute', width:'100%', height:'65px', textAlign:'right', background:'#ccc', bottom:-65}}>
              <div className="line_342">Итого: &nbsp;{data?.length>0 ? data.reduce(function (acc, obj) { return acc + obj.calc_total; }, 0):0} &nbsp;</div>
            </div>
          </TableFooter>
        }
      </Table>
    </TableContainer>
    {
      type === 'one' && 
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={listSize}
            rowsPerPage={perPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePageRow}
            style={{background:'#43849e',color:'#fff'}}
          />
    }
    </>
  );
}
