import React, { useEffect, useState } from 'react';
import Board, { addCard, addColumn, moveCard } from '@lourenci/react-kanban';
import TimeLine from './timeline';
import '@lourenci/react-kanban/dist/styles.css';
import './style.css';
import {
    makeStyles,
    CircularProgress,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from '@material-ui/core';
import {
    Add as AddIcon,
    AttachMoney,
    CalendarToday,
    Close,
    Edit,
    Home,
    TimelapseOutlined,
    Payment,
    PhoneAndroid,
    ShoppingBasket,
} from '@material-ui/icons';

import clsx from 'clsx';

import {
    callDelete,
    callGet,
    categoryGet,
    courierGet,
    getBranches,
    orderPut,
    orderUpdateStatus,
    statusOrderGet,
    tariffGet,
} from '../../../../../utils/apiRequest';
import Alert from '../../../../../components/Alert';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    callDialog,
    courierDialog,
    selectedCallClear,
    selectedCourierClear,
    selectedKanban,
    selectedTariff,
    tariffDialog,
} from '../../../../../redux/actions/KanbanActions';
import ProductTable from './ProductTable';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import ProductDialog from './ProductDialog';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { orderPropList as propList } from '../../../../../constants/propListConstants';
import {
    addEditOrder,
    clearOrder,
    selectedOrders,
} from '../../../../../redux/actions/OrderActions';
import { VALUES} from '../../../../../constants/styleConstants';
import Table from '../../../components/TableViewer';
import getColumns from '../../../../../constants/tableColumnConstants';
import { TABLE_TYPE } from '../../../../../constants/tableConstans';
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
        width: '360px',
    },
    progress: {
        position: 'absolute',
        top: '20%',
        left: '46%',
    },
    hidden: {
        display: 'none',
    },
    title: {
        width: '15.6rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(0, 238, 147, 0.33)',
        padding: '6px 10px',
        borderRadius: '4px 4px 0 0',
    },
    saveBtn: {
        boxShadow: '2px 2px 8px lightgray',
        background: 'white',
        '&:hover': {
            backgroundColor: 'springgreen',
            color: 'white',
        },
    },
    cancelBtn: {
        boxShadow: '2px 2px 8px lightgray',
        background: 'white',
        '&:hover': {
            backgroundColor: 'lightgray',
            color: 'white',
        },
    },
    editbtn: {
        width: '32px',
        height: '32px',
        float: 'right',
        backgroundColor: 'aliceblue',
    },
    received: {
        backgroundColor: '#8bff3ee6',
        padding: '8px 0 4px',
        borderRadius: '0 0 4px 4px',
    },
    cardrow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '12px',
        padding: '2px 10px',
        borderRadius: '4px',
    },
    cardrow2: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: '500',
    },
    cardIcon: {
        width: '16px',
        color: '#166280',
        marginRight: '0.2rem',
    },
    content: {
        padding: 20,
    },
    formControl: {
        width: '100%',
    },
    sectionHeader: {
        color: '#009688',
        fontWeight: 700,
        fontSize: 25,
    },
    secondSection: {
        marginTop: 50,
    },
    colorPrimary: {
        color: VALUES.colorPrimary,
        background: VALUES.colorBgPrimary,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        width: '100%',
        marginTop: '2rem',
    },
    dialogAction: {
        background: 'rgba(0,0,0,0.05)',
    },
    closeButton: {
        position: 'absolute',
        right: '0.5rem',
        top: '0.5rem',
    },
    listItem: {
        cursor: 'pointer',
        transition: '0.3s',
        padding: '0',
        borderRadius: '8px',
        '&:hover': {
            background: 'rgba(0,250,154,0.1)',
        },
    },
}));

function Kanban({ columns = [], data, countBack, waiting, currentUser }) { 

    const classes = useStyles();
    const { t } = useTranslation();

    const [branch, setBranch] = useState(null);
    useEffect(() => {
        getBranches().then((res) => {
            setBranch(res.data.results);
        });
    }, []);

    const kanban = (state) => state.kanban;
    const kanbanData = useSelector(kanban);

    const orders = (state) => state.orders;
    const ordersData = useSelector(orders);

    const dispatch = useDispatch();

    const [board, setBoard] = useState({ columns: [] });
    const [alert, setAlert] = useState({ open: false, severity: '', message: '' });
    const [open, setOpen] = React.useState(false);

    const [isAddModalOpen, setAddModal] = useState(false);
    const [formProps, setFormProp] = useState({
        status: null,
    });
    const [statusOrders, setStatusOrders] = useState([]);
    const [productCategories, setProductCategories] = useState([]);

    useEffect(() => {
        statusOrderGet(1, 100).then((res) => {
            const statuses = res.data?.results;

            if (statuses && Array.isArray(statuses) && statuses.length > 0) {
                const newStatus = orderBy(statuses, ['order_number'], ['asc']);
                setStatusOrders(newStatus);
            }
        });
        categoryGet().then((res) => {
            const productCategories = res.data?.results;
            setProductCategories(productCategories);
        });
        // getApiFunction()
    }, []);

    const handleSelectChange = (e) => {
        setFormProp({
            ...formProps,
            [e.target.name]: e.target.value,
        });
    };
    const [weself, setWeself] = useState(false);
    useEffect(() => {
        if (kanbanData.selectedKanban.order_type === 'weself') {
            setWeself(true);
        } else {
            setWeself(false);
        }
    }, [handleSelectChange]);

    const handleTextChange = (e) => {
        setFormProp({
            ...formProps,
            [e.target.name]: e.target.value,
        });
    };

    function statusToClass(status) {
        let statusClass = '';
        if (status === 'New') {
            statusClass = 'status new';
        } else if (status === 'In Progress') {
            statusClass = 'status progress';
        } else if (status === 'Done') {
            statusClass = 'status done';
        } else {
            statusClass = 'status';
        }

        return statusClass;
    }

    const diffDates = (date, now) => Math.ceil(Math.abs(date - now) / 1000);
    useEffect(() => {
        let newBoard = { columns: [] };

        columns.forEach((el) => {
            newBoard = addColumn(newBoard, { id: el.id, title: el.name, cards: [] });
        });

        if (newBoard.columns.length) {
            data.forEach((el) => {
                let currentBranch = branch.find((branch) => branch.id === el.branch_id);
                const cardHtml = document.querySelectorAll('.react-kanban-card') || null;
                if (!el.is_received && cardHtml) {
                    // cardHtml.style.backgroundColor = 'orange';
                }
                if (el.status && el.status.id) {
                    newBoard = addCard(
                        newBoard,
                        { id: el.status.id }, // Column id should be { id: columnId }
                        {
                            id: el.id,
                            title: (
                                <div className={classes.title}>
                                    <div style={{ color: '#166280' }}>{el.id}</div>
                                    {el.order_type === 'weself' && (
                                        <div style={{ color: '#166280' }}>
                                            {t('orderProps.weself')}
                                        </div>
                                    )}
                                    {el.order_type === 'delivery' && (
                                        <div style={{ color: '#166280' }}>
                                            {t('orderProps.delivery')}
                                        </div>
                                    )}
                                    {el.order_type === 'inplace' && (
                                        <div style={{ color: '#166280' }}>
                                            {t('orderProps.inplace')}
                                        </div>
                                    )}
                                    {el.order_type === 'telegramdelivery' && (
                                        <div style={{ color: '#166280' }}>
                                            {t('orderProps.telegramdelivery')}
                                        </div>
                                    )}
                                    {el.order_type === 'telegramweself' && (
                                        <div style={{ color: '#166280' }}>
                                            {t('orderProps.telegramweself')}
                                        </div>
                                    )}
                                    <IconButton
                                        onClick={() => handleClick(el.id)}
                                        className={classes.editbtn}
                                    >
                                        <Edit className={classes.cardIcon} />
                                    </IconButton>
                                </div>
                            ),
                            description: (
                                <div className={el.is_received && classes.received}>
                                    <div className={classes.cardrow}>
                                        <div className={classes.cardrow2}>
                                            <Payment className={classes.cardIcon} />
                                            <div>{el.payment_method}</div>
                                        </div>
                                        <div className={classes.cardrow2}>
                                            <AttachMoney className={classes.cardIcon} />
                                            <div>{el.subtotal}</div>
                                        </div>
                                    </div>
                                    <div className={classes.cardrow}>
                                        <div className={classes.cardrow2}>
                                            <PhoneAndroid className={classes.cardIcon} />
                                            <div>+{el.user?.phone}</div>
                                        </div>
                                        <div className={classes.cardrow2}>
                                            <CalendarToday className={classes.cardIcon} />
                                            <div>
                                                {new Date(el.created_date)
                                                    .toDateString()
                                                    .slice(4, 11)}
                                                {new Date(el.created_date)
                                                    .toTimeString()
                                                    .slice(0, 5)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.cardrow}>
                                        <div className={classes.cardrow2}>
                                            <Home className={classes.cardIcon} />
                                            <div>{el.shipping_address_txt?.substring(0, 20)}</div>
                                        </div>
                                        <div className={classes.cardrow2}>
                                            <ShoppingBasket className={classes.cardIcon} />
                                            <div>{el.items?.length}</div>
                                        </div>
                                    </div>
                                    {el?.branch_id && (
                                        <div className={classes.cardrow}>
                                            <div className={classes.cardrow2}>
                                                <div>{t('historyProps.branch')}:</div>
                                                <strong style={{ marginLeft: '4px' }}>
                                                    {currentBranch.name}
                                                </strong>
                                            </div>
                                            <div className={classes.cardrow2}>
                                                <TimelapseOutlined className={classes.cardIcon} />
                                                <div>
                                                    {new Date(
                                                        diffDates(
                                                            new Date(el.created_date),
                                                            new Date()
                                                        ) * 1000
                                                    )
                                                        .toISOString()
                                                        .substr(11, 8)}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ),
                        } 
                    );
                }
            });
        }
        let newCountedBoard = { columns: [] };
        newBoard.columns.map((column) => {
            newCountedBoard.columns.push({
                id: column.id,
                title: (
                    <span style={countBack > 30 ? { color: 'red' } : null}>
                        {column.title + '(' + column.cards.length + ')'}
                    </span>
                ),
                cards: column.cards,
            });
        });
        setBoard(newCountedBoard);
    }, [data]);

    function handleAlertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({ ...alert, open: false });
    }

    const [countCalls, setCountCalls] = useState(0);
    const [countCouriers, setCountCouriers] = useState(0);
    const [countTariffs, setCountTariffs] = useState(0);

    const [formOpen, setFormOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pagesToFetch, setPagesToFetch] = useState([1]);
    const [waiting1, setWaiting1] = useState(false);

    const columns_call = getColumns('call', t);
    const columns_courier = getColumns('user', t);
    const columns_tariff = getColumns('tariff', t);

    const handleClick = (id) => {
        setOpen(true);
        const selectedData = data.find((elem) => elem.id === id);
        dispatch(clearOrder());
        selectedData.items.map((item) => {
            dispatch(addEditOrder(item));
            dispatch(selectedOrders(item.product.id));
        });

        dispatch(selectedKanban(selectedData));
    };

    const [calls, setCalls] = useState([]);
    const [tariffs, setTariffs] = useState([]);
    const [couriers, setCouriers] = useState([]);

    const [, setPhoneNumber] = useState([]);

    const getCalls = () => {
        callGet().then((res) => {
            setCalls(res.data.results);
        });
        dispatch(callDialog(true));
    };
    const getCourier = () => {
        courierGet().then((res) => {
            setCouriers(res.data.results);
        });
        dispatch(courierDialog(true));
    };
    const getTariffs = () => {
        tariffGet().then((res) => {
            setTariffs(res.data.results);
        });
        dispatch(tariffDialog(true));
    };
    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            dispatch(clearOrder());
            setFormProp({});
        }, 300);
    };
    const handlePhoneClose = () => {
        dispatch(callDialog(true));
        dispatch(courierDialog(true));
    };

    function handleCardMove(card, source, destination) {
        let status_id = null;

        columns.forEach((status) => {
            if (status.id === destination.toColumnId) {
                status_id = status.id;
            }
        });

        if (status_id && card.id && status_id) {
            if (source.fromColumnId !== destination.toColumnId) {
                const updatedBoard = moveCard(board, source, destination);
                orderUpdateStatus(card.id, { status_id }).catch((err) => {
                    setAlert({
                        open: true,
                        severity: 'error',
                        message: 'Error happened',
                    });
                });
                setBoard(updatedBoard);
            }
        }
    }

    const [items, setItems] = useState([]);
    
    function submit(e) {
        e.preventDefault();

        const statusObj = statusOrders.find((item) => item.id === formProps.status);

        if (items.length === 0) {
            ordersData.editedOrderData.map((orders) => {
                const { id, quantity, modification } = orders;
                if (quantity === undefined) {
                } else {
                    items.push({
                        id: id,
                        quantity: quantity,
                        modification: modification,
                    });
                }
            });
            ordersData.orderData.map((orders) => {
                const { id, quantity, modification } = orders;
                if (quantity === undefined) {
                } else {
                    items.push({
                        id: id,
                        quantity: quantity,
                        modification: modification,
                    });
                }
            });
        }

        const objToSend = {
            ...formProps,
            id: kanbanData.selectedKanban.id,
            status: statusObj,
            items,
            assign: currentUser,

            subtotal:
                parseInt(ordersData.orderTotal) +
                parseInt(formProps.shipping_cost || 0) -
                parseInt(formProps.total_discount || 0),
        };
        if (kanbanData.selectedPhoneNumber) {
            objToSend.phone = kanbanData.selectedPhoneNumber;
        }
        if (kanbanData.selectedDeliveryPrice !== '') {
            objToSend.shipping_cost = kanbanData.selectedDeliveryPrice;
        }
        if (kanbanData.selectedCourierName !== '') {
            objToSend.courier = kanbanData.selectedCourierName;
        }
        // console.log(objToSend);
        if (items.length > 0) {
            orderPut(objToSend.id, objToSend)
                .then((res) => {
                    if (res.status >= 200 && res.status < 300) {
                        console.log(res.data);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            setAlert({ open: true, severity: 'error', message: 'Table Empty' });
        }

        dispatch(selectedCallClear());
        dispatch(selectedCourierClear());
        dispatch(selectedTariff(null));
        setItems([]);
    }


    if (waiting) {
        return (
            <CircularProgress
                className={clsx(classes.progress, !waiting && classes.hidden)}
                size={100}
                color="inherit"
            />
        );
    }

    return (
        <React.Fragment>
            <Board children={board} disableColumnDrag={true} onCardDragEnd={handleCardMove} />

            <Dialog
                maxWidth={'lg'}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ background: 'rgba(0,0,0,0.05)' }}>
                    <div style={{ fontSize: '1.5rem' }} className={classes.sectionHeader}>
                        {kanbanData.selectedKanban.order_type === 'weself' && (
                            <div style={{ color: '#166280' }}>
                                {t('orderProps.weself')}{' '}
                                <span className="id_label">{kanbanData?.selectedKanban?.id}</span>
                            </div>
                        )}
                        {kanbanData.selectedKanban.order_type === 'delivery' && (
                            <div style={{ color: '#166280' }}>
                                {t('orderProps.delivery')}{' '}
                                <span className="id_label">{kanbanData?.selectedKanban?.id}</span>
                            </div>
                        )}
                        {kanbanData.selectedKanban.order_type === 'inplace' && (
                            <div style={{ color: '#166280' }}>
                                {t('orderProps.inplace')}
                                <span className="id_label">{kanbanData?.selectedKanban?.id}</span>
                            </div>
                        )}
                        {kanbanData.selectedKanban.order_type === 'telegramdelivery' && (
                            <div style={{ color: '#166280' }}>
                                {t('orderProps.telegramdelivery')}
                                <span className="id_label">{kanbanData?.selectedKanban?.id}</span>
                            </div>
                        )}
                        {kanbanData.selectedKanban.order_type === 'telegramweself' && (
                            <div style={{ color: '#166280' }}>
                                {t('orderProps.telegramweself')}
                                <span className="id_label">{kanbanData?.selectedKanban?.id}</span>
                            </div>
                        )}
                    </div>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleClose}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container className={classes.content} spacing={3}>
                        <Grid item xs={6}>
                            <form id="order-form" onSubmit={submit}>
                                <Typography variant="h6" className={classes.sectionHeader}>
                                    {t('propList.order')}
                                </Typography>

                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <FormControl
                                            className={classes.formControl}
                                            size="small"
                                            required
                                        >
                                            <InputLabel>{t('orderProps.order_type')}</InputLabel>
                                            <Select
                                                name="order_type"
                                                value={
                                                    formProps['order_type'] ||
                                                    kanbanData.selectedKanban.order_type
                                                }
                                                // defaultValue={kanbanData.selectedKanban.order_type}
                                                onChange={handleSelectChange}
                                            >
                                                {propList[1].enum.map((el) => (
                                                    <MenuItem value={el} key={el}>
                                                        {t(`orderProps.${el}`)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <FormControl
                                            className={classes.formControl}
                                            size="small"
                                            required
                                        >
                                            <InputLabel>
                                                {t('orderProps.payment_method')}
                                            </InputLabel>
                                            <Select
                                                name="payment_method"
                                                value={
                                                    formProps['payment_method'] ||
                                                    kanbanData.selectedKanban.payment_method
                                                }
                                                onChange={handleSelectChange}
                                            >
                                                {propList[2].enum.map((el) => (
                                                    <MenuItem value={el} key={el}>
                                                        {t(`orderProps.${el}`)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <FormControl
                                            className={classes.formControl}
                                            size="small"
                                            required
                                        >
                                            <InputLabel>{t('orderProps.branch')}</InputLabel>
                                            <Select
                                                name="branch"
                                                defaultValue={kanbanData.selectedKanban.branch_id}
                                                onChange={handleSelectChange}
                                            >
                                                {branch?.map((el) => {
                                                    return (
                                                        <MenuItem value={el.id} key={el.id}>
                                                            {el.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {weself === false && (
                                    <div>
                                        <Typography
                                            variant="h6"
                                            className={clsx(
                                                classes.sectionHeader,
                                                classes.secondSection
                                            )}
                                        >
                                            {t('orderProps.shipping_address.self_label')}
                                        </Typography>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    label={t(
                                                        'orderProps.shipping_address.self_label'
                                                    )}
                                                    type="text"
                                                    name="shipping_address_txt"
                                                    value={
                                                        formProps['shipping_address_txt'] ||
                                                        kanbanData.selectedKanban
                                                            .shipping_address_txt
                                                    }
                                                    onChange={handleTextChange}
                                                    margin="dense"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        label={t(
                                                            'orderProps.shipping_address.assign'
                                                        )}
                                                        disabled={true}
                                                        type="text"
                                                        name="courier"
                                                        value={
                                                            kanbanData.selectedCourierName
                                                                ? kanbanData.selectedCourierName
                                                                : kanbanData.selectedKanban?.assign
                                                                      ?.first_name
                                                        }
                                                        margin="dense"
                                                    />
                                                    <div>
                                                        <IconButton
                                                            style={{ marginLeft: '0.5rem' }}
                                                            onClick={getCourier}
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                                <Dialog
                                                    maxWidth={'md'}
                                                    open={kanbanData.courierDialogOpen}
                                                    onClose={handlePhoneClose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogTitle
                                                        id="alert-dialog-title"
                                                        className={classes.dialogTitle}
                                                    >
                                                        <div
                                                            style={{ fontSize: '1.2rem' }}
                                                            className={classes.sectionHeader}
                                                        >
                                                            {t('orderProps.SelectCourier')}
                                                        </div>
                                                        <IconButton
                                                            aria-label="close"
                                                            className={classes.closeButton}
                                                            onClick={handlePhoneClose}
                                                        >
                                                            <Close />
                                                        </IconButton>
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <Table
                                                            rows={couriers}
                                                            count={countCouriers}
                                                            columns={columns_courier}
                                                            waiting={waiting1}
                                                            type={TABLE_TYPE.courier}
                                                            handleFormOpen={setFormOpen}
                                                            reqDelete={callDelete}
                                                            refreshPage={() => setRefresh(!refresh)}
                                                            setPagesToFetch={setPagesToFetch}
                                                        />
                                                    </DialogContent>
                                                </Dialog>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}

                                <Grid
                                    container
                                    spacing={3}
                                    style={{ marginTop: '1rem', alignItems: 'center' }}
                                >
                                    <Grid item xs={6}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label={t('orderProps.shipping_address.phone')}
                                                type="text"
                                                name="phone"
                                                value={
                                                    kanbanData.selectedPhoneNumber
                                                        ? kanbanData.selectedPhoneNumber
                                                        : kanbanData.selectedKanban?.user?.phone
                                                }
                                                onChange={handleTextChange}
                                                margin="dense"
                                                style={{ margin: '0' }}
                                            />
                                            <div>
                                                <IconButton
                                                    style={{ marginLeft: '0.5rem' }}
                                                    onClick={getCalls}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <Dialog
                                            maxWidth={'md'}
                                            open={kanbanData.callDialogOpen}
                                            onClose={handlePhoneClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle
                                                id="alert-dialog-title"
                                                className={classes.dialogTitle}
                                            >
                                                <div
                                                    style={{ fontSize: '1.2rem' }}
                                                    className={classes.sectionHeader}
                                                >
                                                    {t('orderProps.SelectCourier')}
                                                </div>
                                                <IconButton
                                                    aria-label="close"
                                                    className={classes.closeButton}
                                                    onClick={handlePhoneClose}
                                                >
                                                    <Close />
                                                </IconButton>
                                            </DialogTitle>
                                            <DialogContent>
                                                <Table
                                                    rows={calls}
                                                    count={countCalls}
                                                    columns={columns_call}
                                                    waiting={waiting}
                                                    handleFormOpen={setFormOpen}
                                                    reqDelete={callDelete}
                                                    refreshPage={() => setRefresh(!refresh)}
                                                    setPagesToFetch={setPagesToFetch}
                                                />
                                            </DialogContent>
                                        </Dialog>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label={t('orderProps.shipping_address.note')}
                                            type="text"
                                            name="note"
                                            value={
                                                formProps['note'] ||
                                                kanbanData.selectedKanban.note ||
                                                ''
                                            }
                                            onChange={handleTextChange}
                                            margin="dense"
                                            style={{ margin: '0' }}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>

                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                fullWidth
                                className="add-btn"
                                size="large"
                                onClick={() => setAddModal(true)}
                            >
                                <AddIcon />
                            </Button>

                            <ProductTable />
                            {/* asdfsadf */}
                            {weself === false && (
                                <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label={t('orderProps.total_discount')}
                                            type="number"
                                            name="total_discount"
                                            value={
                                                formProps['total_discount'] ||
                                                kanbanData.selectedKanban.total_discount
                                            }
                                            onChange={handleTextChange}
                                            margin="dense"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label={t('orderProps.shipping_cost')}
                                                type="number"
                                                name="shipping_cost"
                                                value={
                                                    formProps['shipping_cost'] ||
                                                    (kanbanData.selectedDeliveryPrice
                                                        ? kanbanData.selectedDeliveryPrice
                                                        : kanbanData.selectedKanban.shipping_cost)
                                                }
                                                onChange={handleTextChange}
                                                margin="dense"
                                            />
                                            <IconButton
                                                style={{ marginLeft: '0.5rem' }}
                                                onClick={getTariffs}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                            <Dialog
                                                maxWidth={'md'}
                                                open={kanbanData?.tariffDialogOpen}
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle
                                                    id="alert-dialog-title"
                                                    className={classes.dialogTitle}
                                                >
                                                    <div
                                                        style={{ fontSize: '1.2rem' }}
                                                        className={classes.sectionHeader}
                                                    >
                                                        {t('tariffProps.delivery_price')}
                                                    </div>
                                                    <IconButton
                                                        aria-label="close"
                                                        className={classes.closeButton}
                                                        onClick={handleClose}
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                </DialogTitle>
                                                <DialogContent>
                                                    <Table
                                                        rows={tariffs}
                                                        count={countTariffs}
                                                        type={TABLE_TYPE.tariff}
                                                        columns={columns_tariff}
                                                        waiting={waiting}
                                                        handleFormOpen={setFormOpen}
                                                        refreshPage={() => setRefresh(!refresh)}
                                                        setPagesToFetch={setPagesToFetch}
                                                    />
                                                </DialogContent>
                                            </Dialog>
                                        </div>
                                    </Grid>
                                </Grid>
                            )}

                            <Box
                                display="flex"
                                flexDirection="row-reverse"
                                p={1}
                                m={1}
                                bgcolor="background.paper"
                            >
                                <Paper className={classes.paper} style={{ width: '200px' }}>
                                    <h3>
                                        {t('orderProps.subtotal')}:
                                        {parseInt(ordersData.orderTotal) +
                                            parseInt(
                                                formProps?.shipping_cost ||
                                                    kanbanData.selectedKanban?.shipping_cost ||
                                                    0
                                            ) -
                                            parseInt(
                                                formProps?.total_discount ||
                                                    kanbanData.selectedKanban?.total_discount ||
                                                    0
                                            )}
                                    </h3>
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                    <Typography
                        variant="h6"
                        className={classes.sectionHeader}
                        style={{ marginLeft: 18 }}
                    >
                        Buyurtma tarixi
                    </Typography>
                    <TimeLine dataById={kanbanData?.selectedKanban} />
                    <ProductDialog
                        open={isAddModalOpen}
                        setOpen={setAddModal}
                        productCategories={productCategories}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogAction} style={{ padding: '0.8rem' }}>
                    <Button onClick={handleClose} color="primary" className={classes.cancelBtn}>
                        {t('orderProps.cancel')}
                    </Button>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        style={{ marginLeft: '1rem' }}
                        type="submit"
                        form="order-form"
                        className={classes.saveBtn}
                        autoFocus
                    >
                        {t('orderProps.saveChanges')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Alert
                open={alert.open}
                severity={alert.severity}
                message={alert.message}
                onClose={handleAlertClose}
            />
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
    };
};
export default connect(mapStateToProps)(Kanban);
