import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, Paper } from '@material-ui/core';
// import styleConstants from '../../../constants/styleConstants';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Timeline from "../views/forms/orderComponents/timeline"
const OrderHistory = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();

    const orderDetails = useSelector((state) => state.orders.orderDetails);

    return (
        <div className={classes.root}>
            <div style={{display: "flex", justifyContent: 'space-between'}}>
                <h4><strong>{t('orderProps.OrderId')} <span style={{ color: 'tomato' }}>#{orderDetails?.main_order_id || '###'}</span></strong></h4>
                <Button
                    variant="contained"
                    onClick={() => history.push('/history')}
                    color="primary">
                    {t('sidebar.go_back')}
                </Button>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <div className={classes.paper}>
                        <div className={classes.tableHeader}>
                            <div className={classes.summary}>
                                <strong style={{ fontSize: '1.2rem' }}>{t('orderProps.ItemsSummary')}</strong>
                            </div>
                            <div className={classes.name}>
                                <strong>{t('orderProps.price')}</strong>
                            </div>
                            <div className={classes.name}>
                                <strong>{t('orderProps.quantity')}</strong>
                            </div>
                            <div className={classes.name}>
                                <strong>{t('orderProps.total')}</strong>
                            </div>
                        </div>
                        {
                            orderDetails?.items ? 
                            orderDetails?.items?.map(item => {
                                const { product } = item;
                                return (
                                    <div key={item.id} className={classes.tableRow}>
                                        <div className={classes.summary}>
                                            <div className="d-flex align-items-center">
                                                <img width={64} className="rounded-3"
                                                    src={`${product.photo.image}`}
                                                    alt="image"/>
                                                <div className="ms-2">{product.name_uz}</div>
                                            </div>
                                        </div>
                                        <div className={classes.name}>{product.price}</div>
                                        <div className={classes.name}>{item.quantity}</div>
                                        <div
                                            className={classes.name}>{(item.quantity * parseInt(product.price)).toFixed(2)}</div>
                                    </div>
                                );
                            }):null
                        }
                    </div>
                    <div className={classes.paper}>
                        <div className={classes.tableHeader}>
                            <div className={classes.summary}>
                                <strong style={{ fontSize: '1.2rem' }}>{t('orderProps.OrderDetails')}</strong>
                            </div>
                        </div>
                        <div className={classes.tableRow}>
                            <strong>{t('orderProps.status')}</strong>
                            <div className={classes.status2}>
                                <strong style={{ color: 'rgb(50,21,109)' }}>{orderDetails?.status?.name}</strong>
                            </div>
                        </div>
                        <div className={classes.tableRow}>
                            <strong>{t('orderProps.created')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {new Date(orderDetails?.created_date).toTimeString().slice(0, 5)} {new Date(orderDetails?.created_date).toDateString().slice(4, 15)}
                            </strong>
                        </div>
                        <div className={classes.tableRow}>
                            <strong>{t('orderProps.modified')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {new Date(orderDetails?.modified_date).toTimeString().slice(0, 5)} {new Date(orderDetails?.modified_date).toDateString().slice(4, 15)}
                            </strong>
                        </div>
                        <div className={classes.tableRow}>
                            <strong>{t('orderProps.branch')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {orderDetails.branch_id === 1 ? 'Lola' : 'Sardoba'}
                            </strong>
                        </div>
                        <div className={classes.tableRow}>
                            <strong>{t('orderProps.order_type')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {orderDetails.order_type === 'weself' && (
                                    <div style={{ color: '#166280' }}>
                                        {t('orderProps.weself')}
                                    </div>
                                )}
                                {orderDetails.order_type === 'delivery' && (
                                    <div style={{ color: '#166280' }}>
                                        {t('orderProps.delivery')}
                                    </div>
                                )}
                                {orderDetails.order_type === 'inplace' && (
                                    <div style={{ color: '#166280' }}>
                                        {t('orderProps.inplace')}
                                    </div>
                                )}
                                {orderDetails.order_type === 'telegramdelivery' && (
                                    <div style={{ color: '#166280' }}>
                                        {t('orderProps.telegramdelivery')}
                                    </div>
                                )}
                                {orderDetails.order_type === 'telegramweself' && (
                                    <div style={{ color: '#166280' }}>
                                        {t('orderProps.telegramweself')}
                                    </div>
                                )}</strong>
                        </div>
                        <div className={classes.tableRow}>
                            <strong>{t('orderProps.shipping_address.self_label')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {orderDetails.shipping_address_txt ? orderDetails.shipping_address_txt : 'Not Entered'}
                            </strong>
                        </div>
                        <div className={classes.tableRow}>
                            <strong>{t('orderProps.shipping_address.note')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {orderDetails.note ? orderDetails.note : 'Not Entered'}
                            </strong>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={classes.paper}>
                        <div
                            className={classes.tableHeader}
                            style={{ borderBottom: `2px solid rgba(0,0,0,0.1)` }}
                        >
                            <div className={classes.summary}>
                                <strong style={{ fontSize: '1.2rem' }}>{t('orderProps.CustomerDetails')}</strong>
                            </div>
                        </div>
                        <div className={classes.tableRow2}>
                            <strong>{t('orderProps.fullName')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {orderDetails.user?.first_name + ' ' + orderDetails.user?.last_name}
                            </strong>
                        </div>
                        <div className={classes.tableRow2}>
                            <strong>{t('userProps.phone')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {orderDetails.user?.phone ? orderDetails.user.phone : 'Not Entered'}
                            </strong>
                        </div>
                        <div className={classes.tableRow2}>
                            <strong>{t('userProps.email')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {orderDetails.user?.email ? orderDetails.user.email : 'Not Entered'}
                            </strong>
                        </div>
                        <div className={classes.tableRow2}>
                            <strong>{t('userProps.main_order_id')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {orderDetails.main_order_id ? orderDetails.main_order_id : 'Not Entered'}
                            </strong>
                        </div>
                    </div>
                    <div className={classes.paper}>
                        <div
                            className={classes.tableHeader}
                            style={{ borderBottom: `2px solid rgba(0,0,0,0.1)` }}
                        >
                            <div className={classes.summary}>
                                <strong style={{ fontSize: '1.2rem' }}>{t('orderProps.CourierDetails')}</strong>
                            </div>
                        </div>
                        <div className={classes.tableRow2}>
                            <strong>{t('orderProps.fullName')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {orderDetails.assign?.first_name + ' ' + orderDetails.assign?.last_name}
                            </strong>
                        </div>
                        <div className={classes.tableRow2}>
                            <strong>{t('userProps.phone')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {orderDetails.assign?.phone ? orderDetails.assign.phone : 'Not Entered'}
                            </strong>
                        </div>
                        <div className={classes.tableRow2}>
                            <strong>{t('userProps.email')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {orderDetails.assign?.email ? orderDetails.assign.email : 'Not Entered'}
                            </strong>
                        </div>
                    </div>
                    <div className={classes.paper}>
                        <div
                            className={classes.tableHeader}
                            style={{ borderBottom: `2px solid rgba(0,0,0,0.1)` }}
                        >
                            <div className={classes.summary}>
                                <strong style={{ fontSize: '1.2rem' }}>{t('orderProps.OrderSummary')}</strong>
                            </div>
                            <div className={classes.status}>
                                <strong style={{ color: 'rgb(50,21,109)' }}>{orderDetails?.status?.name}</strong>
                            </div>
                        </div>
                        <div className={classes.tableRow2}>
                            <strong>{t('orderProps.payment_method')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>{orderDetails.payment_method}</strong>
                        </div>
                        {/*<div className={classes.tableRow2}>*/}
                        {/*    <strong>Shipping Address</strong>*/}
                        {/*    <strong style={{ color: 'rgb(50,21,109)' }}>{orderDetails.shipping_address_txt}</strong>*/}
                        {/*</div>*/}
                        <div className={classes.tableRow2}>
                            <strong>{t('orderProps.subtotal')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>{orderDetails.subtotal}</strong>
                        </div>
                        <div className={classes.tableRow2}>
                            <strong>{t('orderProps.shipping_cost')}</strong>
                            <strong style={{ color: 'rgb(50,21,109)' }}>{orderDetails.shipping_cost}</strong>
                        </div>
                    </div>
                    <div className={classes.paper}>
                        <div className={classes.tableHeader} style={{ border: 'none', padding: '0' }}>
                            <div className={classes.summary}>
                                <strong style={{ fontSize: '1.2rem' }}>Total</strong>
                            </div>
                            <strong style={{ color: 'rgb(50,21,109)' }}>
                                {parseInt(orderDetails.shipping_cost) + parseInt(orderDetails.subtotal)}
                            </strong>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.paper}>
                <div className={classes.tableHeader}>
                    <div className={classes.summary}>
                        <strong style={{ fontSize: '1.2rem' }}>Buyurtma tarixi</strong>
                    </div>
                </div>
                <Timeline dataById={orderDetails}/>
            </div>
        </div>
    );
};

export default OrderHistory;

// Styles
const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: '400'
    },
    paper: {
        background: 'white',
        padding: '1rem 2rem',
        borderRadius: '1rem',
        marginTop: '1rem',
        '&  $lastChild': { borderBottom: 'none' }
    },
    tableHeader: {
        // borderBottom: `2px solid rgba(0,0,0,0.1)`,
        padding: '1rem 0.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '1rem',
        position: 'relative'
    },
    tableRow: {
        borderTop: `2px solid rgba(0,0,0,0.1)`,
        padding: '1rem 0.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative'
    },
    tableRow2: {
        margin: '0.8rem 0.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    summary: {
        flex: 1
    },
    name: {
        flex: 0.5
    },
    status: {
        position: 'absolute',
        top: '0.9rem',
        right: '0',
        padding: '0.3rem 1rem',
        borderRadius: '1rem',
        background: 'rgba(43,155,251,0.2)'
    },
    status2: {
        position: 'absolute',
        top: '0.7rem',
        right: '0',
        padding: '0.3rem 1rem',
        borderRadius: '1rem',
        background: 'rgba(43,155,251,0.2)'
    }
}));
