import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {getResponse} from "../../../requests/getApi"
import ProductsForm from './forms/branchForm';
import Table from '../components/TableView';
import FormController from '../components/FormController';
import { productPropList as propList } from '../../../constants/propListConstants';
import getColumns from '../../../constants/tableColumnConstants';
import {
    productGet,
    // productGetById,
    productPost,
    modificationPost,
    productPut,
    modificationPut,
    productDelete,
    moderationDelete,
    moderationGet,
    branchGet,
    branchDelete,
} from '../../../utils/apiRequest';
import moment from 'moment';
//

export default function ProductsView(props) {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pagesToFetch, setPagesToFetch] = useState([]);
    const [editId, setEditId] = useState(null)
    const [waiting, setWaiting] = useState(false);
    const [callback, setCallback] = useState(false);
    const { t } = useTranslation();
    const columns = getColumns('branch', t);

    useEffect(() => {
        setWaiting(true);
        // getResponse('/modifications/?page=1&page_size=3', setState)
        const prms = [];
        for (let i of pagesToFetch) prms.push(branchGet(i));

        Promise.all(prms)
            .then((res) => {
                // console.log(res);
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });
                let date = obj.map((item) => {
                    return {
                        id:item.id,
                        name:item.name,
                        branch_Type:item?.branch_type,
                        address:item.address,
                        phone:item.phone,
                        lat:item.latitude,
                        lng:item.longitude,
                        created_date:moment(item?.created_date).format("lll")
                    }
                })
                console.log(obj);
                setData([...data, ...date]);
                setCount(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setData([]);
            })
            .finally(() => setWaiting(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagesToFetch]);
 
    useEffect(() => {
        setData([]);
        setPagesToFetch([1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);
    console.log(data)
    return (
        <>
            <Table
                rows={data}
                count={count}
                columns={columns}
                handleFormOpen={setFormOpen}
                waiting={waiting}
                refreshPage={() => {
                    setRefresh(!refresh);
                }}
                setPagesToFetch={setPagesToFetch}
                reqDelete={branchDelete}
                disableDelete
                setEdit={setEditId}
                emptyList={waiting ? false :data?.length>0 ? false:true}
            />
            <FormController
                open={formOpen}
                data={data}
                propList={propList}
                header={t('propList.product')}
                setEdit={setEditId}
                render={(input, handleTextChange, handleFileChange) => (
                    <ProductsForm
                        edit={editId}
                        input={input}
                        callback={refresh}
                        setCallback={setRefresh}
                        handleTextChange={handleTextChange}
                        handleFileChange={handleFileChange}
                    />
                )}
                handleClose={() => {
                    setFormOpen(false);
                }}
                refreshPage={() => {
                    setRefresh(!refresh);
                }}
                reqPost={modificationPost}
                reqPatch={modificationPut}
                reqDelete={branchDelete}
            />
        </>
    );
}
