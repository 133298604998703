import React, { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    makeStyles,
    Divider,
    ButtonBase,
} from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import defProduct from '../../../../assets/images/defProduct.webp';
import { postResponse, putResponse, getResponse } from '../../../../requests/getApi';
import { useSelector } from 'react-redux';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../../constants/styleConstants';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { categoryGet } from '../../../../utils/apiRequest';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
//

export default function ProductsForm(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useStyles = makeStyles((theme) => ({
        imageBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        image: {
            height: '140px',
            width: '180px',
            marginBottom: '10px',
            border: '1px solid black',
            borderRadius: '5px',
            objectFit: 'contain',
        },
        uploadBtn: {
            padding: '4px',
        },
        root: {
            padding: '8px',
        },
        divider: {
            margin: '20px 0',
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        colorHeader: {
            color: colorValues.colorHeader,
        },

        //
        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
    }));
    const { edit } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { callback, setCallback } = props;
    const [putReq, setPutReq] = useState(false);
    const [getById, setGetById] = useState({ success: '', error: '' });
    const [load, setLoad] = React.useState(false);
    const [state, setState] = React.useState({
        name: '',
        branch_type: 'server',
        latitude: '',
        longitude: '',
        phone: '',
        address: '',
        is_default: false,
    });
    const [error, setError] = React.useState({ name: false, branch_type: false });
    const [postData, setPostData] = React.useState({ success: '', error: '' });
    useEffect(() => {
        getResponse(`/branches/${edit?.id}/`, setGetById);
    }, [edit]);
    useEffect(() => {
        if (getById?.success !== '') {
            setState({
                name: getById?.success?.data?.name,
                branch_type: getById?.success?.data?.branch_type,
                longitude: getById?.success?.data?.longitude,
                latitude: getById?.success?.data?.latitude,
                phone: getById?.success?.data?.phone,
                address: getById?.success?.data?.address,
                is_default: getById?.success?.data?.is_default,
                created_date: getById?.success?.data?.created_date,
            });
            setPutReq(true);
        }
    }, [getById]);
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };
    const handleSubmit = () => {
        setLoad(true);
        if (state?.name === '' || state?.branch_type === '') {
            if (state?.name === '') {
                setError({ ...error, name: true });
            }
            setLoad(false);
        } else {
            postResponse('/branches/', state, setPostData);
        }
    };
    const handleSubmitPut = () => {
        setLoad(true);
        if (state?.name === '' || state?.branch_type === '') {
            if (state?.name === '') {
                setError({ ...error, name: true });
            }
            setLoad(false);
        } else {
            putResponse(`/branches/${edit?.id}/`, state, setPostData);
        }
    };
    React.useEffect(() => {
        if (!(postData?.success === '' && postData?.error === '')) {
            setLoad(false);
        }
        if (postData?.success !== '') {
            setCallback(!callback);
        }
    }, [postData]);
    React.useEffect(() => {
        if (state?.name !== '') setError({ ...error, name: false });
    }, [state]);
    return (
        <>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />

            <Grid container style={{ alignItems: 'flex-start', justifyContent: 'center' }}>
                <Grid item xs={12} sm={6} md={4} style={{ padding: '0 5px' }}>
                    <TextField
                        fullWidth
                        error={error?.name || false}
                        variant="outlined"
                        id="name"
                        label="Nomi"
                        type="text"
                        value={state?.name || ''}
                        onChange={handleChange}
                        margin="dense"
                        style={{ margin: '10px 0' }}
                        name="name"
                    />
                    <TextField
                        fullWidth
                        error={error?.branch_type || false}
                        type="text"
                        variant="outlined"
                        id="name"
                        label="Filial turi"
                        value={state?.branch_type || ''}
                        onChange={handleChange}
                        margin="dense"
                        style={{ margin: '10px 0' }}
                        name="branch_type"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} style={{ padding: '0 5px' }}>
                    <TextField
                        fullWidth
                        error={error?.latitude || false}
                        type="text"
                        variant="outlined"
                        id="name"
                        label="Latitude"
                        value={state?.latitude || ''}
                        onChange={handleChange}
                        margin="dense"
                        style={{ margin: '10px 0' }}
                        name="latitude"
                    />
                    <TextField
                        fullWidth
                        error={error?.longitude || false}
                        type="text"
                        variant="outlined"
                        id="name"
                        label="Longitude"
                        value={state?.longitude || ''}
                        onChange={handleChange}
                        margin="dense"
                        style={{ margin: '10px 0' }}
                        name="longitude"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} style={{ padding: '0 5px' }}>
                    <TextField
                        fullWidth
                        error={error?.phone || false}
                        type="text"
                        variant="outlined"
                        id="name"
                        label="Telefon"
                        value={state?.phone || ''}
                        onChange={handleChange}
                        margin="dense"
                        style={{ margin: '10px 0' }}
                        name="phone"
                    />
                    <TextField
                        fullWidth
                        error={error?.address || false}
                        type="text"
                        variant="outlined"
                        id="name"
                        label="Address"
                        value={state?.address || ''}
                        onChange={handleChange}
                        margin="dense"
                        style={{ margin: '10px 0' }}
                        name="address"
                    />
                </Grid>
            </Grid>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 5px',
                }}
            >
                {Object.keys(edit).length > 0 ? (
                    <LoadingButton
                        onClick={handleSubmitPut}
                        endIcon={
                            postData?.success !== '' ? (
                                <CheckCircleOutlineIcon />
                            ) : postData?.error !== '' ? (
                                <ErrorOutlineIcon />
                            ) : (
                                <SendIcon />
                            )
                        }
                        style={
                            postData?.success !== ''
                                ? { backgroundColor: 'green' }
                                : postData?.error !== ''
                                ? { backgroundColor: 'red' }
                                : null
                        }
                        loading={load}
                        loadingPosition="end"
                        variant="contained"
                    >
                        {postData?.success === '' && postData?.error === ''
                            ? 'Yangilash'
                            : postData?.success !== '' && postData?.error == ''
                            ? 'Yangilandi'
                            : 'Xatolik yuz berdi'}
                    </LoadingButton>
                ) : (
                    <LoadingButton
                        onClick={handleSubmit}
                        endIcon={
                            postData?.success !== '' ? (
                                <CheckCircleOutlineIcon />
                            ) : postData?.error !== '' ? (
                                <ErrorOutlineIcon />
                            ) : (
                                <SendIcon />
                            )
                        }
                        style={
                            postData?.success !== ''
                                ? { backgroundColor: 'green' }
                                : postData?.error !== ''
                                ? { backgroundColor: 'red' }
                                : null
                        }
                        loading={load}
                        loadingPosition="end"
                        variant="contained"
                    >
                        {postData?.success === '' && postData?.error === ''
                            ? 'Yaratish'
                            : postData?.success !== '' && postData?.error == ''
                            ? 'Yaratildi'
                            : 'Xatolik yuz berdi'}
                    </LoadingButton>
                )}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        color="primary"
                        checked={state?.is_default}
                        onChange={(e) => setState({ ...state, is_default: e.target.checked })}
                    />
                    <div>Default</div>
                </div>
            </div>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />
        </>
    );
}
