import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
    Paper,
    Checkbox,
    Box,
    Button,
    CircularProgress,
    makeStyles,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';

import Confirm from '../../../components/Confirm';
import Alert from '../../../components/Alert';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../constants/styleConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
    courierDialog,
    selectedCourier,
    callDialog,
    selectedTariff,
    selectedCall,
    tariffDialog,
    userDialog,
    selectedKanban,
    selectedAddressText,
    selectedBranch,
} from '../../../redux/actions/KanbanActions';
import { TABLE_TYPE } from '../../../constants/tableConstans';
import { getBranches } from '../../../utils/apiRequest';
import styled from '@material-ui/core/styles/styled';

// Utility Functions
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// Header

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(230, 246, 255, 0.5)',
        border: 'none',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function EnhancedTableHead(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useTableHeadStyles = makeStyles((theme) => ({
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },

        colorPrimary: {
            background: colorValues.colorBgPrimary,
            color: '#FFF',
        },
        c: {
            '& path': {
                fill: '#FFF',
            },
        },
    }));
    const classes = useTableHeadStyles();

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.colorPrimary}>
            <TableRow>
                {props.columns.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        size={'small'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            classes={{ icon: classes.c }}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Typography className={classes.colorPrimary}>
                                {headCell.label}
                            </Typography>
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

// Styles

export default function EnhancedTable(props, { isOrder }) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },

        //
        boxBtns: {
            marginBottom: '10px',
        },
        boxBtn: {
            marginRight: '5px',
        },
        boxTable: {
            height: 1170,
            width: '100%',
            marginTop: '10px',
            overflow: 'auto hidden',
        },
        tablePagination: {
            background: colorValues.colorBgPrimary,
            color: '#FFF',
        },
        progress: {
            position: 'absolute',
            top: '20%',
            left: '46%',
        },
        hidden: {
            display: 'none',
        },
        //
        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
    }));
    const classes = useStyles();
    const { t } = useTranslation();
    const { setState } = props;
    const dispatch = useDispatch();

    // pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const rowsInPage = 20;

    useEffect(() => {
        const current = props.rows.length;
        const required =
            props.count > 0
                ? (page + 1) * rowsPerPage > props.count
                    ? props.count
                    : (page + 1) * rowsPerPage
                : (page + 1) * rowsPerPage;
        const diff = required - current;
        // const cPage = Math.ceil((page * rowsPerPage) / rowsInPage + 1);
        const cPage = Math.ceil(current / rowsInPage + 1);

        if (diff > 0) {
            const arr = [];
            for (let i = cPage; i < cPage + Math.ceil(diff / rowsInPage); i++) {
                if (i > current / rowsInPage) arr.push(i);
            }
            if (arr.length > 0) props.setPagesToFetch(arr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    // table functionality
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = props.rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);

    // logic
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [alert, setAlert] = useState({ open: false, severity: '', message: '' });

    function handleConfirmDelete() {
        const prms = [];
        selected.forEach((el) => {
            prms.push(props.reqDelete(el * 1));
        });

        Promise.all(prms)
            .then((responds) => {
                responds.forEach((res) => {
                    if (res.status <= 200 || res.status > 300)
                        setAlert({
                            open: true,
                            severity: 'error',
                            message: Object.values(res.data)[0],
                        });
                });
                setAlert({ open: true, severity: 'success', message: 'Deleted' });

                setSelected([]);
                setDeleteConfirmOpen(false);
                console.log('im callin');
                props.refreshPage();
            })
            .catch((err) => console.error(err));
    }

    function handleAlertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({ ...alert, open: false });
    }

    const selectItem = (item) => {
        localStorage.setItem('selectedItem', JSON.stringify(item));
        if (props.type === TABLE_TYPE.phone) {
            dispatch(selectedCall(item.phone_number));
            dispatch(callDialog(false));
        } else if (props.type === TABLE_TYPE.courier) {
            // console.log(item);
            // setState(prev=>{return{...prev, customer_name:item?.user?.first_name}})
            dispatch(selectedCourier(item));
            dispatch(courierDialog(false));
        } else if (props.type === TABLE_TYPE.tariff) {
            // console.log(item);
            dispatch(selectedTariff(item));
            // setState(prev=>{return{...prev, customer_name:item?.user?.first_name}})
            dispatch(tariffDialog(false));
        }
        setState((prev) => {
            return {
                ...prev,
                customer_name: item?.user?.first_name,
            };
        });
        console.log(item);
    };

    const selectUserHistory = (item) => {
        console.log(item);
        // dispatch(selectedKanban(item));
        dispatch(selectedCall(item.user.phone));
        dispatch(selectedAddressText(item.shipping_address_txt));
        dispatch(selectedBranch(item.branch_id));

        dispatch(callDialog(false));
        dispatch(userDialog(false));
    };

    const [branch, setBranch] = useState(null);
    useEffect(() => {
        getBranches().then((res) => {
            setBranch(res.data.results);
        });
    }, []);
    // console.log(branch);

    const getUserHistory = (userId) => {
        // console.log(userId);
        dispatch(
            userDialog({
                dialog: true,
                id: userId,
            })
        );
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer style={{ position: 'relative' }}>
                    <CircularProgress
                        className={clsx(classes.progress, !props.waiting && classes.hidden)}
                        size={100}
                        color="inherit"
                    />

                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={props.rows.length}
                            columns={props.columns}
                            disableCheckbox={props.disableCheckbox}
                        />
                        <TableBody>
                            {stableSort(props.rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    // console.log(row);
                                    return (
                                        <StyledTableRow
                                            hover
                                            style={{ cursor: 'pointer' }}
                                            key={row.id}
                                        >
                                            {props.columns.map((el, i) => {
                                                // console.log(row[el.id]);
                                                if (props.type === TABLE_TYPE.courier) {
                                                    if (el.type === 'button') {
                                                        // console.log(row);
                                                        return (
                                                            <TableCell align="left" key={i}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <Button
                                                                        style={{
                                                                            backgroundColor:
                                                                                'rgb(0,196,121)',
                                                                            color: 'white',
                                                                            fontSize: '14px',
                                                                            padding: '4px 6px',
                                                                        }}
                                                                        onClick={() =>
                                                                            selectItem(row)
                                                                        }
                                                                    >
                                                                        {t('userProps.select')}
                                                                    </Button>
                                                                </div>
                                                            </TableCell>
                                                        );
                                                    }
                                                } else if (props.type === TABLE_TYPE.tariff) {
                                                    if (el.type === 'button') {
                                                        // console.log(row);
                                                        return (
                                                            <TableCell align="left" key={i}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <Button
                                                                        style={{
                                                                            backgroundColor:
                                                                                'rgb(0,196,121)',
                                                                            color: 'white',
                                                                            fontSize: '14px',
                                                                            padding: '4px 6px',
                                                                        }}
                                                                        onClick={() =>
                                                                            selectItem(row)
                                                                        }
                                                                    >
                                                                        {t('userProps.select')}
                                                                    </Button>
                                                                </div>
                                                            </TableCell>
                                                        );
                                                    }
                                                } else if (props.type === TABLE_TYPE.phone) {
                                                    if (el.id === 'first_name') {
                                                        return (
                                                            <TableCell align="left" key={i}>
                                                                {row.user?.first_name}
                                                            </TableCell>
                                                        );
                                                    }
                                                    if (el.id === 'last_name') {
                                                        return (
                                                            <TableCell align="left" key={i}>
                                                                {row.user?.last_name}
                                                            </TableCell>
                                                        );
                                                    }
                                                    if (el.type === 'button') {
                                                        return (
                                                            <TableCell align="left" key={i}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <Button
                                                                        style={{
                                                                            backgroundColor:
                                                                                'rgb(0,196,121)',
                                                                            color: 'white',
                                                                            fontSize: '14px',
                                                                            padding: '4px 6px',
                                                                        }}
                                                                        onClick={() =>
                                                                            selectItem(row)
                                                                        }
                                                                    >
                                                                        {t('userProps.select')}
                                                                    </Button>
                                                                    {row.user !== null && (
                                                                        <Button
                                                                            style={{
                                                                                backgroundColor:
                                                                                    'rgb(225,156,49)',
                                                                                marginLeft: '8px',
                                                                                color: 'white',
                                                                                fontSize: '14px',
                                                                                padding: '4px 6px',
                                                                            }}
                                                                            onClick={() =>
                                                                                getUserHistory(
                                                                                    row.user?.id
                                                                                )
                                                                            }
                                                                        >
                                                                            {t('userProps.view')}
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                                {/*{row.user.last_name}*/}
                                                            </TableCell>
                                                        );
                                                    }
                                                } else if (props.type === TABLE_TYPE.user) {
                                                    if (el.id === 'created_date') {
                                                        return (
                                                            <TableCell align="left" key={i}>
                                                                {new Date(row[el.id])
                                                                    .toUTCString()
                                                                    .slice(5, 22)}
                                                            </TableCell>
                                                        );
                                                    }
                                                    if (el.id === 'branch_id') {
                                                        let currentBranch = branch?.find(
                                                            (branch) => branch.id === row.branch_id
                                                        );
                                                        // console.log(currentBranch?.name, "asa");
                                                        return (
                                                            <TableCell align="left" key={i}>
                                                                {currentBranch?.name}
                                                            </TableCell>
                                                        );
                                                    }
                                                    if (el.type === 'button') {
                                                        // console.log(row);
                                                        return (
                                                            <TableCell align="left" key={i}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <Button
                                                                        style={{
                                                                            backgroundColor:
                                                                                'rgb(0,196,121)',
                                                                            color: 'white',
                                                                            fontSize: '14px',
                                                                            padding: '4px 6px',
                                                                        }}
                                                                        onClick={() =>
                                                                            selectUserHistory(row)
                                                                        }
                                                                    >
                                                                        {t('userProps.select')}
                                                                    </Button>
                                                                </div>
                                                            </TableCell>
                                                        );
                                                    }
                                                }

                                                return (
                                                    <TableCell align="left" key={i}>
                                                        {row[el.id]}
                                                    </TableCell>
                                                );
                                            })}
                                        </StyledTableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} size={'small'} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    classes={{ toolbar: classes.tablePagination }}
                    rowsPerPageOptions={[10, 15, 20, 25]}
                    component="div"
                    labelRowsPerPage={t('table.rows_per_page')}
                    count={props.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Confirm
                open={deleteConfirmOpen}
                header={t('table.deleteConfirm.header')}
                text={t('table.deleteConfirm.text')}
                handleClose={() => {
                    setDeleteConfirmOpen(false);
                }}
                handleConfirm={handleConfirmDelete}
            />
            <Alert
                open={alert.open}
                severity={alert.severity}
                message={alert.message}
                onClose={handleAlertClose}
            />
        </div>
    );
}
