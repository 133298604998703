import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../components/TableView';
import FormController from '../components/FormController';
import UsersForm from './forms/sessions.form';
import { SessionPropList } from '../../../constants/propListConstants';
import getColumns from '../../../constants/tableColumnConstants';
import * as cookies from '../../../utils/Cookies';
import {
      sessionGet,
    sessionPost,
    sessionPut,
    sessionDelete,
} from '../../../utils/apiRequest';

export default function Users() {
    const lang = cookies.get('lang')
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pagesToFetch, setPagesToFetch] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const { t } = useTranslation();

    const columns = getColumns('session', t);

    useEffect(() => {
        setWaiting(true);
        const prms = [];
        for (let i of pagesToFetch) prms.push(sessionGet(i));

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });
                setData([...data,...obj]);
                setCount(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setData([]);
            })
            .finally(() => setWaiting(false));
    }, [pagesToFetch]);
    useEffect(() => {
        setData([]);
        setPagesToFetch([1]);
    }, [refresh]);
    return (
        <>
            <Table
                rows={data?.map(item=>{return{...item,branch:item?.branch?.name, user:item?.user?.first_name}})}
                count={count}
                columns={columns}
                waiting={waiting}
                handleFormOpen={setFormOpen}
                reqDelete={sessionDelete}
                refreshPage={() => setRefresh(!refresh)}
                setPagesToFetch={setPagesToFetch}
                emptyList={data?.length === 0 && !waiting}
                disableCreate
            />
            <FormController
                open={formOpen}
                data={data}
                propList={SessionPropList}
                header={t('propList.user')}
                render={(input, handleTextChange, handleFileChange) => (
                    <UsersForm
                        input={input}
                        handleTextChange={handleTextChange}
                        handleFileChange={handleFileChange}
                    />
                )}
                handleClose={() => {
                    setFormOpen(false);
                }}
                refreshPage={() => {
                    setRefresh(!refresh)
                }}
                reqPost={sessionPost}
                reqPatch={sessionPut}
            />
        </>
    );
}
