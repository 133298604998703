import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { connect, useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    Grid,
    InputLabel,
    Select,
    TextField,
    FormControl,
    MenuItem,
    DialogTitle,
    DialogContent,
    
} from '@material-ui/core';

import {
    Add as AddIcon, Close,
} from '@material-ui/icons';
import {
    orderPost, getBranches, callGet, callDelete, courierGet, tariffGet
} from '../../../../../utils/apiRequest';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../../../constants/styleConstants';
import "./timeline.order.css"
import ProductTable from './ProductTable';
import ProductDialog from './ProductDialog';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { clearOrder } from '../../../../../redux/actions/OrderActions';
import Table from '../../../components/TableViewer';
import Alert from '../../../../../components/Alert';
import getColumns from '../../../../../constants/tableColumnConstants';
import {
    courierDialog,
    callDialog,
    tariffDialog,
    selectedCourierClear,
    selectedCallClear, selectedTariff, selectedAddressText
} from '../../../../../redux/actions/KanbanActions';
import { TABLE_TYPE } from '../../../../../constants/tableConstans';
import UserDialog from '../../../components/UserDialog';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function OrderForm({ isOpen, close, refreshPage, collection = {}, currentUser }) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'red'
                },
                '&:hover fieldset': {
                    borderColor: 'yellow'
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'green'
                }
            },
            width: '360px',
            backgroundColor: theme.palette.background.paper
        },
        appBar: {
            position: 'relative'
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1
        },
        content: {
            padding: 20
        },
        formControl: {
            width: '100%'
        },
        sectionHeader: {
            color: '#009688',
            fontWeight: 700,
            fontSize: 25
        },
        sectionHeaderLast:{
            color: '#009688',
            fontWeight: 700,
            fontSize: 25,
            marginLeft:20,
        },
        secondSection: {
            marginTop: 28
        },
        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
            width: '100%',
            marginTop: '1rem'
        },
        dialogTitle: {
            borderBottom: '1px solid rgba(0,0,0,0.1)',
            display: 'flex!important',
            justifyContent: 'space-between'
        },
        closeButton: {
            position: 'absolute',
            right: '0.5rem',
            top: '0.5rem'
        },
        listItem: {
            cursor: 'pointer',
            transition: '0.3s',
            padding: '0',
            borderRadius: '8px',
            '&:hover': {
                background: 'rgba(0,250,154,0.1)'
            }
        }
    }));
    const classes = useStyles();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [branch, setBranch] = useState(null);
    const [edit, setEdit] = useState(true);
    useEffect(() => {
        getBranches().then(res => {
            setBranch(res.data.results);
        });
    }, []);

    const orders = (state) => state.orders;
    const ordersData = useSelector(orders);

    const kanban = (state) => state.kanban;
    const kanbanData = useSelector(kanban);

    const [isAddModalOpen, setAddModal] = useState(false);
    const [alert, setAlert] = useState({ open: false, severity: '', message: '' });
    const [formProps, setFormProp] = useState({
        status: null,
    });

    const {
        statusOrders,
        propList,
        productCategories
    } = collection;

    const handleSelectChange = (e) => {
        setFormProp({
            ...formProps,
            [e.target.name]: e.target.value
        });
    };
    const [weself, setWeself] = useState(false);
    useEffect(() => {
        if (formProps.order_type === 'weself') {
            setWeself(true);
        } else {
            setWeself(false);
        }
    }, [handleSelectChange]);

    const handleTextChange = (e) => {
        setFormProp({
            ...formProps,
            [e.target.name]: e.target.value
        });
    };

    const closeModal = () => {
        setFormProp({});
        close();
        dispatch(clearOrder());
        dispatch(selectedCallClear());
        dispatch(selectedCourierClear());
    };
    const [calls, setCalls] = useState([]);
    const [tariffs, setTariffs] = useState([]);
    const [couriers, setCouriers] = useState([]);

    const getCalls = () => {
        callGet().then(res => {
            setCalls(res.data.results);
        });
        dispatch(callDialog(true));
    };
    const getCouriers = () => {
        courierGet().then(res => {
            setCouriers(res.data.results);
        });
        dispatch(courierDialog(true));
    };
    const getTariffs = () => {
        tariffGet().then(res => {
            setTariffs(res.data.results);
        });
        dispatch(tariffDialog(true));
    };

    const handleClose = () => {
        dispatch(courierDialog(false));
        dispatch(callDialog(false));
        dispatch(tariffDialog(false));
    };

    function submit(e) {
        e.preventDefault();

        const statusObj = statusOrders.find((item) => item.id === formProps.status);

        if (!items.length) {
            ordersData.orderData.map(orders => {
                const { id, quantity } = orders;
                if (quantity === undefined) {

                } else {
                    items.push({
                        id: id,
                        quantity: quantity
                    });
                }
            });
        }

        let objToSend;


        if (formProps.order_type === 'delivery') {
            objToSend = {
                ...formProps,
                status: statusObj,
                items,
                courier: kanbanData.selectedCourier.id,
                phone: formProps.phone || kanbanData.selectedPhoneNumber,
                is_active: true,

                subtotal: parseInt(ordersData.orderTotal)
                    + parseInt(kanbanData.selectedDeliveryPrice)
                    - parseInt(formProps.total_discount || 0),
                shipping_cost: formProps.shipping_cost || kanbanData.selectedDeliveryPrice
            };
        } else {
            objToSend = {
                ...formProps,
                status: statusObj,
                items,
                courier: kanbanData.selectedCourier.id,
                phone: formProps.phone || kanbanData.selectedPhoneNumber,
                is_active: true,

                subtotal: parseInt(ordersData.orderTotal) - parseInt(formProps.total_discount || 0)
               
            };
        }
        if (kanbanData.selectedAddressText !== '') objToSend.shipping_address_txt = kanbanData.selectedAddressText;
        if (kanbanData.selectedBranch !== null) objToSend.branch = kanbanData.selectedBranch;

        console.log(objToSend);

        if (items.length > 0) {
            orderPost(objToSend)
                .then((res) => {
                    if (res.status >= 200 && res.status < 300) {
                        close();
                        window.location.reload(false);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            setAlert({ open: true, severity: 'error', message: 'Table Empty' });
        }

        dispatch(selectedCallClear());
        dispatch(selectedAddressText(''));
        dispatch(selectedCourierClear());
        dispatch(selectedTariff({
            min_price: ''
        }));
    }

    function handleAlertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({ ...alert, open: false });
    }

    const [data, setData] = useState([]);

    const [items, setItems] = useState([]);
    const [countCalls, setCountCalls] = useState(0);
    const [countCouriers, setCountCouriers] = useState(0);
    const [countTariffs, setCountTariffs] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pagesToFetchCalls, setPagesToFetchCalls] = useState([1]);
    const [pagesToFetchCouriers, setPagesToFetchCouriers] = useState([1]);
    const [pagesToFetchTariffs, setPagesToFetchTariffs] = useState([1]);
    const [waiting, setWaiting] = useState(false);

    const columns_call = getColumns('call', t);
    const columns_courier = getColumns('user', t);
    const columns_tariff = getColumns('tariff', t);

    useEffect(() => {
        setWaiting(true);
        const prms = [];
        for (let i of pagesToFetchCalls) prms.push(callGet(i));

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    console.log(res);
                    obj.push(...el.data.results);
                });

                setCalls([...calls, ...obj]);
                setCountCalls(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setCalls([]);
            })
            .finally(() => setWaiting(false));
    }, pagesToFetchCalls);
    useEffect(() => {
        setWaiting(true);
        const prms = [];
        for (let i of pagesToFetchCouriers) prms.push(courierGet(i));

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });

                setCouriers([...couriers, ...obj]);
                setCountCouriers(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setCouriers([]);
            })
            .finally(() => setWaiting(false));
    }, pagesToFetchCouriers);
    useEffect(() => {
        setWaiting(true);
        const prms = [];
        for (let i of pagesToFetchTariffs) prms.push(tariffGet(i));

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });

                setTariffs([...tariffs, ...obj]);
                setCountTariffs(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setTariffs([]);
            })
            .finally(() => setWaiting(false));
    }, pagesToFetchTariffs);

    useEffect(() => {
        setCalls([]);
        setCouriers([]);
        setTariffs([]);
        setPagesToFetchCalls([1]);
        setPagesToFetchCouriers([1]);
        setPagesToFetchTariffs([1]);
    }, [refresh]);
    console.log(formProps)

    return (
        <Dialog fullScreen open={!!isOpen} onClose={closeModal} TransitionComponent={Transition}>
            <AppBar className={clsx(classes.appBar, classes.colorPrimary)}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {t('propList.order')}
                    </Typography>
                    <Button color="inherit" type="submit" form="order-form">
                        <Typography variant="h6">{t('formControl.save')}</Typography>
                    </Button>
                </Toolbar>
            </AppBar>

            <Grid container className={classes.content} spacing={5}>
                <Grid item xs={6}>
                    <form id="order-form" onSubmit={submit}>
                        <Typography variant="h6" className={classes.sectionHeader}>
                            {t('propList.order')}
                        </Typography>

                        <Grid container spacing={3}>

                            <Grid item xs={4}>
                                <FormControl
                                    className={classes.formControl}
                                    size="small"
                                    required
                                >
                                    <InputLabel>{t('orderProps.order_type')}</InputLabel>
                                    <Select
                                        name="order_type"
                                        value={formProps['order_type'] || ''}
                                        onChange={handleSelectChange}
                                    >
                                        {
                                            propList[1].enum.map((el) => (
                                                <MenuItem value={el} key={el}>
                                                    {t(`orderProps.${el}`)}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl
                                    className={classes.formControl}
                                    size="small"
                                    required
                                >
                                    <InputLabel>{t('orderProps.payment_method')} </InputLabel>
                                    <Select
                                        name="payment_method"
                                        value={formProps['payment_method'] || ''}
                                        onChange={handleSelectChange}
                                    >
                                        {
                                            propList[2].enum.map((el) => (
                                                <MenuItem value={el} key={el}>
                                                    {t(`orderProps.${el}`)}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl
                                    className={classes.formControl}
                                    size="small"
                                    required
                                >
                                    <InputLabel>{t('orderProps.branch')}</InputLabel>
                                    {/*<InputLabel>Branch</InputLabel>*/}
                                    {kanbanData?.selectedBranch ? (
                                        <Select
                                            name="branch"
                                            value={kanbanData?.selectedBranch}
                                            onChange={handleSelectChange}
                                        >
                                            {
                                                branch?.map((el) => (
                                                    <MenuItem value={el.id} key={el.id}>
                                                        {el.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    ) : (
                                        <Select
                                            name="branch"
                                            value={formProps['branch'] || ''}
                                            onChange={handleSelectChange}
                                        >
                                            {
                                                branch?.map((el) => (
                                                    <MenuItem value={el.id} key={el.id}>
                                                        {el.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    )}
                                </FormControl>
                            </Grid>

                        </Grid>
                        {weself === false && (
                            <div>
                                <Typography variant="h6" className={clsx(classes.sectionHeader, classes.secondSection)}>
                                    {t('orderProps.shipping_address.self_label')}
                                </Typography>

                                <Grid container spacing={3}>
                                    <Grid item xs={11}>
                                        {kanbanData?.selectedAddressText ? (
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label={t('orderProps.shipping_address.self_label')}
                                                type="text"
                                                name="shipping_address_txt"
                                                value={kanbanData?.selectedAddressText}
                                                onChange={handleTextChange}
                                                margin="dense"
                                            />
                                        ): (
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label={t('orderProps.shipping_address.self_label')}
                                                type="text"
                                                name="shipping_address_txt"
                                                value={formProps['shipping_address_txt'] || kanbanData?.selectedAddressText}
                                                onChange={handleTextChange}
                                                margin="dense"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label={t('orderProps.shipping_address.assign')}
                                                disabled={true}
                                                type="text"
                                                name="courier"
                                                value={formProps['courier'] || kanbanData?.selectedCourierName}
                                                onChange={handleTextChange}
                                                margin="dense"
                                                required
                                            />
                                            <div>
                                                <IconButton style={{ marginLeft: '0.5rem' }} onClick={getCouriers}>
                                                    <AddIcon/>
                                                </IconButton>
                                            </div>
                                        </div>
                                        <Dialog
                                            maxWidth={'md'}
                                            open={kanbanData?.courierDialogOpen}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
                                                <div style={{ fontSize: '1.2rem' }} className={classes.sectionHeader}>
                                                    {t('orderProps.SelectCourier')} 
                                                </div>
                                                <IconButton aria-label="close" className={classes.closeButton}
                                                            onClick={handleClose}>
                                                    <Close/>
                                                </IconButton>
                                            </DialogTitle>
                                            <DialogContent>
                                                <Table
                                                    rows={couriers}
                                                    count={countCouriers}
                                                    columns={columns_courier}
                                                    waiting={waiting}
                                                    type={TABLE_TYPE.courier}
                                                    handleFormOpen={setFormOpen}
                                                    reqDelete={callDelete}
                                                    refreshPage={() => setRefresh(!refresh)}
                                                    setPagesToFetch={setPagesToFetchCouriers}
                                                />
                                            </DialogContent>
                                        </Dialog>
                                    </Grid>
                                </Grid>
                            </div>
                        )}

                        <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label={t('historyProps.shipping_address.customer_name')}
                                    type="text"
                                    name="customer_name"
                                    value={formProps['customer_name'] ||''}
                                    onChange={handleTextChange}
                                    margin="dense"
                                    style={{ marginTop: '4px' }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {kanbanData?.selectedAddressText ? (
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label={t('orderProps.shipping_address.phone')}
                                            type="tel"
                                            name="phone"
                                            value={kanbanData?.selectedPhoneNumber}
                                            onChange={handleTextChange}
                                            margin="dense"
                                            style={{ margin: '0' }}
                                            required
                                        />
                                    ):(
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label={t('orderProps.shipping_address.phone')}
                                            type="tel"
                                            name="phone"
                                            value={formProps['phone'] || kanbanData?.selectedPhoneNumber}
                                            onChange={handleTextChange}
                                            margin="dense"
                                            style={{ margin: '0' }}
                                            required
                                        />
                                    )}
                                    <div>
                                        <IconButton style={{ marginLeft: '0.5rem' }} onClick={getCalls}>
                                            <AddIcon/>
                                        </IconButton>
                                    </div>
                                </div>
                                <Dialog
                                    maxWidth={'md'}
                                    open={kanbanData?.callDialogOpen}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
                                        <div style={{ fontSize: '1.2rem' }} className={classes.sectionHeader}>
                                            {t('orderProps.phoneNumber')}
                                        </div>
                                        <IconButton aria-label="close" className={classes.closeButton}
                                                    onClick={handleClose}>
                                            <Close/>
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Table
                                            rows={calls}
                                            count={countCalls}
                                            columns={columns_call}
                                            waiting={waiting}
                                            handleFormOpen={setFormOpen}
                                            reqDelete={callDelete}
                                            refreshPage={() => setRefresh(!refresh)}
                                            setPagesToFetch={setPagesToFetchCalls}
                                            setState={setFormProp}
                                        />
                                    </DialogContent>
                                </Dialog>
                            </Grid>
                            <Grid item xs={11}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label={t('orderProps.shipping_address.note')}
                                    type="text"
                                    name="note"
                                    value={formProps['note'] || ''}
                                    onChange={handleTextChange}
                                    margin="dense"
                                    style={{ marginTop: '4px' }}
                                />
                            </Grid>
                        </Grid>

                    </form>
                </Grid>

                <Grid item xs={6}>
                    <Button
                        variant="outlined"
                        fullWidth
                        className="add-btn"
                        size="large"
                        onClick={() => setAddModal(true)}
                    >
                        <AddIcon/>
                    </Button>

                    <ProductTable edit={true} productCategories={productCategories}/>

                    {weself === false && (
                        <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label={t('orderProps.total_discount')}
                                    type="number"
                                    name="total_discount"
                                    value={formProps['total_discount'] || ''}
                                    onChange={handleTextChange}
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label={t('orderProps.shipping_cost')}
                                        type="number"
                                        name="shipping_cost"
                                        value={formProps['shipping_cost'] || kanbanData?.selectedDeliveryPrice}
                                        onChange={handleTextChange}
                                        margin="dense"
                                    />

                                    <IconButton style={{ marginLeft: '0.5rem' }} onClick={getTariffs}>
                                        <AddIcon/>
                                    </IconButton>
                                    <Dialog
                                        maxWidth={'md'}
                                        open={kanbanData?.tariffDialogOpen}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
                                            <div style={{ fontSize: '1.2rem' }} className={classes.sectionHeader}>
                                                {t('tariffProps.delivery_price')}
                                            </div>
                                            <IconButton aria-label="close" className={classes.closeButton}
                                                        onClick={handleClose}>
                                                <Close/>
                                            </IconButton>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Table
                                                rows={tariffs}
                                                count={countTariffs}
                                                type={TABLE_TYPE.tariff}
                                                columns={columns_tariff}
                                                waiting={waiting}
                                                handleFormOpen={setFormOpen}
                                                refreshPage={() => setRefresh(!refresh)}
                                                setPagesToFetch={setPagesToFetchTariffs}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </div>
                            </Grid>
                        </Grid>
                    )}

                    <Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper">
                        <Paper className={classes.paper} style={{ width: '200px' }}>
                            <h3>{t('orderProps.subtotal')}:
                                {parseInt(ordersData.orderTotal) + parseInt(formProps?.shipping_cost || kanbanData.selectedDeliveryPrice || 0)
                                - parseInt(formProps?.total_discount || 0)}
                            </h3>
                        </Paper>
                    </Box>

                </Grid>
            </Grid>

            <UserDialog/>

            <ProductDialog
                open={isAddModalOpen}
                setOpen={setAddModal}
                productCategories={productCategories}
            />

            <Alert
                open={alert.open}
                severity={alert.severity}
                message={alert.message}
                onClose={handleAlertClose}
            />
            {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis aperiam nobis maxime aliquam repellendus neque iure quas? Maiores sed dolorem culpa suscipit? Et laborum sapiente dolore quo, voluptate tempora maxime? */}
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser
    };
};

export default connect(mapStateToProps)(OrderForm);
